<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />

        <!-- Filtros -->
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container>
            <v-row justify="end">
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <!-- Fecha desde -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-menu
                      ref="menuDesde"
                      v-model="menuDesde"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Fecha desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          outlined
                          clearable
                          dense
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          v-on="on"
                          :rules="[
                            (!!fechaDesdeSelected &&
                              !!fechaHastaSelected &&
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected))) ||
                              'Formato incorrecto'
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menuDesde = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha hasta -->
                  <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-menu
                      v-model="menuHasta"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Fecha hasta"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :rules="[
                            (!!fechaDesdeSelected &&
                              !!fechaHastaSelected &&
                              new Date(parseDateToIso(fechaDesdeSelected)) <=
                                new Date(parseDateToIso(fechaHastaSelected))) ||
                              'Formato incorrecto'
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menuHasta = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="pr-4 pl-4 pt-0" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            class="elevation-1"
            :headers="headers"
            :items="procesosDistribucion"
            item-key="impAporte_id"
            :search="search"
            :loading="isLoading"
            show-expand
            :expanded.sync="expanded"
          >
            <template v-slot:[`item.esDistribucion`]="{ item }">
              <v-icon v-if="item.esDistribucion" small color="primary">
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:[`item.esFiscalizacion`]="{ item }">
              <v-icon v-if="item.esFiscalizacion" small color="primary">
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      class="to-right"
                      @click="openModalEditProceso()"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <section style="display: inline" v-on="on">
                    <v-icon
                      :disabled="item.fechaAnula !== ''"
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="verDetalleProceso(item)"
                    >
                      {{ seeIcon }}
                    </v-icon>
                  </section>
                </template>
                <span>{{
                  item.fechaAnula === ""
                    ? "Ver detalles"
                    : "El proceso ya ha sido anulado"
                }}</span>
              </v-tooltip>
              <v-tooltip left v-if="canAnular">
                <template v-slot:activator="{ on, attrs }">
                  <section style="display: inline" v-on="on">
                    <v-icon
                      :disabled="item.fechaAnula !== ''"
                      v-bind="attrs"
                      size="20"
                      v-on="on"
                      @click="anularProceso(item.impAporte_id)"
                    >
                      {{ anularIcon }}
                    </v-icon>
                  </section>
                </template>
                <span>{{
                  item.fechaAnula === ""
                    ? "Anular proceso"
                    : "El proceso ya ha sido anulado"
                }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{ isExpanded ? accountCheckIcon : accountIcon }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row>
                  <v-col cols="12" md="3">
                    <strong>Usuario alta:</strong>
                    {{ item.usuarioAlta }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <strong>Fecha alta:</strong>
                    {{ item.fechaAlta }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <strong>Usuario anulación:</strong>
                    {{ item.usuarioAnula ? item.usuarioAnula : "Sin datos" }}
                  </v-col>
                  <v-col cols="12" md="3">
                    <strong>Fecha anulación:</strong>
                    {{ item.fechaAnula ? item.fechaAnula : "Sin datos" }}
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalVerDetalle"
      v-model="openModalVerDetalle"
      max-width="80%"
      @keydown.esc="closeModalVerDetalle"
      persistent
    >
      <VerDetalleProcesoDistribucion
        :detallesDistribucionAportes="procesoADetallar"
        @closeModalVerDetalle="closeModalVerDetalle"
      ></VerDetalleProcesoDistribucion
    ></v-dialog>
    <DeleteDialog
      :titleProp="titleAnular"
      :textDelete="textAnular"
      :maxWidth="'40%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showAnularModal"
      :isLoading="loadingAnular"
      @onDeleteItem="confirmAnular()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import NuevoProcesoDistribucionAportes from "@/components/modules/cuotas/aportes/NuevoProcesoDistribucionAportes.vue";
import VerDetalleProcesoDistribucion from "@/components/modules/cuotas/aportes/VerDetalleProcesoDistribucion.vue";

export default {
  name: "CargaAportes",
  components: {
    PageHeader,
    GoBackBtn,
    Ayuda,
    FiltersSelected,
    DeleteDialog,
    NuevoProcesoDistribucionAportes,
    VerDetalleProcesoDistribucion
  },
  data() {
    return {
      loadingAnular: false,
      searchIcon: enums.icons.SEARCH,
      search: "",
      isLoading: false,
      showExpand: false,
      showHelp: false,
      optionCode: enums.webSiteOptions.CARGA_APORTES,
      routeToGo: "DDJJyAportes",
      title: enums.titles.CARGA_APORTES,
      rules: rules,
      openModalVerDetalle: false,
      procesoADetallar: null,
      headers: [
        { text: "N° de proceso", value: "impAporte_id", sortable: false },
        { text: "Fecha de proceso", value: "fechaProceso", sortable: false },
        { text: "Convenio", value: "osNombre", sortable: false },
        { text: "Tipo importación", value: "tipo", sortable: false },
        { text: "Empresa", value: "archivo", sortable: false },
        {
          text: "Es distribución",
          value: "esDistribucion",
          sortable: false,
          align: "center"
        },
        {
          text: "Es fiscalización",
          value: "esFiscalizacion",
          sortable: false,
          align: "center"
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        { text: "", align: "end", value: "data-table-expand", width: "1%" }
      ],
      calendarIcon: enums.icons.CALENDAR,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      anularIcon: enums.icons.CIRCLE_NONE,
      checkIcon: enums.icons.CHECK_OUTLINE,
      seeIcon: enums.icons.SEE,
      accountIcon: enums.icons.ACCOUNT,
      accountCheckIcon: enums.icons.ACCOUNT_CHECK,
      allowedActions: null,
      canCreate: false,
      canAnular: false,
      menuDesde: false,
      menuHasta: false,
      fechaDesdeSelected: null,
      fechaHastaSelected: null,
      fechaDesde: null,
      fechaHasta: null,
      showFilters: true,
      showFiltersSelected: false,
      isFormValid: false,
      procesosDistribucion: [],
      filtersApplied: [],
      expanded: [],
      showAnularModal: false,
      titleAnular: "¿Desea anular el proceso?",
      textAnular: "Se eliminarán todos los aportes creados por el proceso.",
      anularBoton: "Anular",
      prueba: true
    };
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
    this.getFechas();
  },
  methods: {
    ...mapActions({
      fetchProcesosDistribucion: "aportes/fetchProcesosDistribucion",
      anularProcesoDistribucion: "aportes/anularProcesoDistribucion",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVO_PROCESO_DISTRIBUCION_APORTES_WEB:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .ANULAR_PROCESO_DISTRIBUCION_APORTES_WEB:
            this.canAnular = true;
            break;
          default:
            break;
        }
      });
    },
    async applyFilters() {
      this.isLoading = true;
      const data = {
        fechaDesde: this.fechaDesdeSelected,
        fechaHasta: this.fechaHastaSelected
      };
      try {
        const response = await this.fetchProcesosDistribucion(data);
        this.customizeFiltersApplied();
        this.showFilters = false;
        this.showFiltersSelected = true;
        this.procesosDistribucion = response;
        this.isLoading = false;
      } catch (error) {}
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    getFechas() {
      let now = new Date();
      let año = now.getFullYear();
      let mes = now.getMonth() + 1;
      let dia = now.getDate();
      dia < 10 ? (dia = `0${dia}`) : dia;
      mes < 10 ? (mes = `0${mes}`) : mes;
      let one = new Date(now.setMonth(now.getMonth() - 6));
      let oneAño = one.getFullYear();
      let oneMes = one.getMonth() + 1;
      let oneDia = one.getDate();
      oneDia < 10 ? (oneDia = `0${oneDia}`) : oneDia;
      oneMes < 10 ? (oneMes = `0${oneMes}`) : oneMes;
      this.fechaDesde = `${oneAño}-${oneMes}-${oneDia}`;
      this.fechaDesdeSelected = this.formatDate(this.fechaDesde);
      this.fechaHasta = `${año}-${mes}-${dia}`;
      this.fechaHastaSelected = this.formatDate(this.fechaHasta);
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },

    closeOpenFilters() {
      this.showFiltersSelected = !this.showFiltersSelected;
      this.showFilters = !this.showFilters;
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "fechaDesde",
          label: "Fecha desde",
          model: this.formatDate(this.fechaDesde)
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "fechaHasta",
          label: "Fecha hasta",
          model: this.formatDate(this.fechaHasta)
        });
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
      this.procesosDistribucion = [];
      this.filtersApplied = [];
    },
    anularProceso(id) {
      this.showAnularModal = true;
      this.idToAnular = id;
    },
    async confirmAnular() {
      this.loadingAnular = true;
      const response = await this.anularProcesoDistribucion(this.idToAnular);
      if (response.status === 200) {
        this.loadingAnular = false;
        this.showAnularModal = false;
        this.setAlert({
          type: "success",
          message: "El proceso ha sido anulado."
        });
        if (this.isFormValid) {
          this.applyFilters();
        }
      }
    },
    verDetalleProceso(item) {
      let procesoADetallar = this.procesosDistribucion.find(
        x => x.impAporte_id == item.impAporte_id
      );
      this.procesoADetallar = procesoADetallar;
      this.openModalVerDetalle = true;
    },
    closeModalVerDetalle() {
      this.openModalVerDetalle = false;
    },

    openModalEditProceso() {
      this.$router.push({
        name: "NuevoProcesoDistribucionAportes"
      });
      this.$store.dispatch("user/changeAppLoading", {
        status: true,
        text: "",
        opaque: true
      });
    }
  }
};
</script>
