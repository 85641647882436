<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
    <PageHeader :title="title" />
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-form
              v-model="isFormValid"
              ref="form-nuevo-proceso-distrib-aportes"
              form="form"
              id="form"
              @submit.prevent="saveEdit()"
            >
              <v-card-title class="h6 py-0 px-0"
                >Datos de la empresa</v-card-title
              >
              <v-row>
                <!-- Empresa -->
                <v-col cols="6" md="6" class="py-0">
                  <v-autocomplete
                    ref="empresa-autocomplete"
                    v-model="empresaSelected"
                    :items="empresas"
                    :search-input.sync="empresaAutocompleteInput"
                    item-text="value"
                    item-value="id"
                    :filter="customFilter"
                    :rules="rules.required"
                    outlined
                    :disabled="listaAportesContribuciones.length > 0"
                    return-object
                    clearable
                    dense
                    autocomplete="off"
                    label="Empresa (*)"
                    hint="Nombre o CUIT"
                    persistent-hint
                  >
                    <template slot="item" slot-scope="{ item }">
                      <v-list-item-content>
                        <v-list-item-title>{{ item.value }}</v-list-item-title>
                        <v-list-item-subtitle
                          >CUIT: {{ item.cuit }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <template v-slot:append>
                      <v-progress-circular
                        indeterminate
                        size="28"
                        v-if="empresasLoading"
                        color="primary"
                      ></v-progress-circular>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!-- Convenios -->
                <!-- Convenio -->
                <v-col cols="4" md="4" class="py-0">
                  <v-autocomplete
                    v-model="convenioSelected"
                    :items="convenios"
                    item-text="value"
                    :disabled="listaAportesContribuciones.length > 0"
                    item-value="id"
                    :rules="rules.required"
                    outlined
                    dense
                    autocomplete="off"
                    label="Convenio (*)"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="2" md="2" class="pt-2 pb-0">
                  <v-tooltip right max-width="15rem">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon class="primary--text" v-bind="attrs" v-on="on">
                        {{ infoIcon }}
                      </v-icon>
                    </template>
                    <span>
                      Convenio para grabar los aportes en afiliados sin convenio
                      al período ingresado</span
                    >
                  </v-tooltip>
                </v-col>
                <v-col class="py-0" align="left">
                  <v-subheader v-if="listaAportesContribuciones.length > 0"
                    >Para seleccionar otra empresa y/o convenio, debe eliminar
                    todos los registros de la lista</v-subheader
                  >
                  <v-subheader v-else>(*) Datos obligatorios. </v-subheader>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-data-table
                    :headers="headersLista"
                    :items="listaAportesContribuciones"
                    class="elevation-1"
                    item-key="id"
                    dense
                  >
                    <template v-slot:[`item.esFiscalizacion`]="{ item }">
                      <v-icon v-if="item.esFiscalizacion" small color="primary">
                        {{ checkIcon }}
                      </v-icon>
                    </template>
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-row>
                          <v-col cols="10">
                            <v-card-title class="h6 py-0 px-0"
                              >Lista de aportes y contribuciones a
                              distribuir</v-card-title
                            >
                          </v-col>
                          <v-col cols="2" align="end">
                            <v-btn
                              color="primary"
                              class="to-right"
                              :disabled="
                                empresaSelected == null ||
                                  convenioSelected == null
                              "
                              @click="openModalAporteContribADistribuir()"
                            >
                              Nuevo
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-toolbar>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            size="20"
                            v-on="on"
                            @click="quitarRegistro(item.id)"
                          >
                            {{ deleteIcon }}
                          </v-icon>
                        </template>
                        <span>Quitar registro</span>
                      </v-tooltip>
                    </template>
                  </v-data-table></v-col
                >
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-col cols="6" class="py-0">
        <v-btn
          class="mt-2 py-4 to-right fontsize"
          align="end"
          type="submit"
          :loading="loadingGrabarBtn"
          :disabled="!isFormValid || listaAportesContribuciones.length === 0"
          @click="openModalGrabarProcesoDistribAportes()"
          form="form"
          color="primary"
        >
          Procesar
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalAporteContribADistribuir"
      v-model="modalAporteContribADistribuir"
      max-width="40%"
      @keydown.esc="modalAporteContribADistribuir = false"
      persistent
    >
      <AporteContribucionADistribuir
        @setRegistro="setRegistro($event)"
        :empresa="empresaSelected"
        :listaAportesContribuciones="listaAportesContribuciones"
        @closeModal="modalAporteContribADistribuir = false"
      ></AporteContribucionADistribuir>
    </v-dialog>
    <v-dialog
      v-if="modalGrabarProcesoDistribAportes"
      v-model="modalGrabarProcesoDistribAportes"
      max-width="80%"
      @keydown.esc="modalGrabarProcesoDistribAportes = false"
      persistent
    >
      <GrabarProcesoDistribucionAportes
        @closeModal="modalGrabarProcesoDistribAportes = false"
        @closeAndReload="closeAndReload()"
        :osId="convenioSelected"
        :empId="empresaSelected.id"
        :listaProcesadaDistribucionAportes="listaAportesContribucionesProcesada"
        :procesosDistribucion="listaAportesContribuciones"
      ></GrabarProcesoDistribucionAportes>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmQuitarRegistro()"
    />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import DeleteDialog from "@/components/shared/DeleteDialog";
import GoBackBtn from "@/components/shared/GoBackBtn";
import AporteContribucionADistribuir from "@/components/modules/cuotas/aportes/AporteContribucionADistribuir.vue";
import GrabarProcesoDistribucionAportes from "@/components/modules/cuotas/aportes/GrabarProcesoDistribucionAportes.vue";

export default {
  name: "NuevoProcesoDistribucionAportes",
  props: {
    compId: {
      type: Number,
      required: false
    }
  },
  components: {
    PageHeader,
    GoBackBtn,
    AporteContribucionADistribuir,
    DeleteDialog,
    GrabarProcesoDistribucionAportes
  },
  directives: { mask },
  watch: {
    empresaAutocompleteInput(val) {
      if (val && val.length >= 3) {
        this.getEmpresasFilter();
      }
    }
  },
  data: () => ({
    isFormValid: false,
    routeToGo: "CargaAportes",
    title: "Nuevo proceso de distribución de aportes",
    deleteIcon: enums.icons.DELETE,
    checkIcon: enums.icons.CHECK_OUTLINE,
    rules: rules,
    listaAportesContribuciones: [],
    infoIcon: enums.icons.SNB_INFO,
    headersLista: [
      {
        text: "Período",
        align: "start",
        sortable: false,
        value: "periodo"
      },
      { text: "Aporte", value: "aporte", sortable: false, align: "end" },
      {
        text: "Contribución",
        value: "contribucion",
        sortable: false,
        align: "end"
      },
      { text: "Otros", value: "otros", sortable: false, align: "end" },
      { text: "Origen", value: "origen", sortable: false },
      { text: "Período origen", sortable: false, value: "periodoConsulta" },
      { text: "Cant. Cuiles", sortable: false, value: "cantidadCuiles" },
      {
        text: "Es Fiscalización",
        sortable: false,
        value: "esFiscalizacion",
        align: "center"
      },
      { text: "Total base", sortable: false, value: "totalBase", align: "end" },
      { text: "Acciones", value: "actions", align: "end", sortable: false }
    ],
    empresaAutocompleteInput: null,
    empresasLoading: null,
    empresas: [],
    empresaSelected: null,
    modalAporteContribADistribuir: false,
    showDeleteModal: false,
    titleDelete: "¿Quitar registro?",
    convenios: [],
    convenioSelected: null,
    modalGrabarProcesoDistribAportes: false,
    loadingGrabarBtn: false,
    listaAportesContribucionesProcesada: []
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setSelects();
  },
  methods: {
    ...mapActions({
      fetchEmpresasByQuery: "aportes/fetchEmpresasByQuery",
      getConvenios: "afiliaciones/getConvenios",
      procesarProcesoDistribucionAportes:
        "aportes/procesarProcesoDistribucionAportes",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      const convenios = await this.getConvenios();
      this.convenios = convenios;
    },
    customFilter(item, queryText, itemText) {
      return (
        item.nombreCuit ||
        item.cuit.indexOf(queryText) > -1 ||
        item.value.indexOf(queryText) > -1
      );
    },
    getEmpresasFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(async () => {
        if (
          this.empresas?.some(
            x => x.nombreCuit == this.empresaAutocompleteInput
          )
        ) {
          return;
        } else if (!this.banderaEmpresaSelected) {
          this.empresasLoading = true;
          const res = await this.fetchEmpresasByQuery({
            input: this.empresaAutocompleteInput
          });
          this.$refs["empresa-autocomplete"].cachedItems = [];
          this.empresas = res;
          this.empresasLoading = false;
        } else {
          this.empresasLoading = true;
          const response = await this.fetchEmpresasByQuery({
            input: this.cuil
          });
          this.empresas = response;
          this.empresaSelected = response[0];
          // ^^ evita que se borre el campo empresaAutocompleteInput al setear los datos
          this.empresasLoading = false;
        }
      }, 800);
    },

    async saveEdit() {},
    closeModal() {
      this.$emit("closeAndReload");
    },
    closeAndReload() {
      this.$refs["form-nuevo-proceso-distrib-aportes"].reset();
      this.modalGrabarProcesoDistribAportes = false;
      this.empresaSelected = null;
      this.empresaAutocompleteInput = null;
      this.empresas = [];
      this.convenioSelected = null;
      this.listaAportesContribuciones = [];
    },
    openModalAporteContribADistribuir() {
      this.modalAporteContribADistribuir = true;
    },
    async openModalGrabarProcesoDistribAportes() {
      this.loadingGrabarBtn = true;
      const data = {
        procesosDistribucion: this.listaAportesContribuciones,
        empId: this.empresaSelected.id
      };
      try {
        const response = await this.procesarProcesoDistribucionAportes(data);
        if (response.status === 200) {
          this.loadingGrabarBtn = false;
          this.modalGrabarProcesoDistribAportes = true;
          this.listaAportesContribucionesProcesada = response.data.data;
        }
      } catch (error) {
        this.loadingGrabarBtn = false;
      }
    },
    setRegistro(registro) {
      this.modalAporteContribADistribuir = false;
      this.listaAportesContribuciones.push(registro);
    },
    quitarRegistro(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    confirmQuitarRegistro(id) {
      this.listaAportesContribuciones = this.listaAportesContribuciones.filter(
        x => x.id !== this.idToDelete
      );
      this.showDeleteModal = false;
    }
  }
};
</script>

<style scoped>
::v-deep .theme--light.v-card > .v-card__text {
  color: rgba(56, 56, 56, 1);
}
::v-deep .theme--light.v-input input {
  color: rgb(56, 56, 56);
}

::v-deep .v-select__selection,
.v-select__selection--comma,
.v-select__selection--disabled input {
  color: black !important;
}

::v-deep .v-input__slot {
  cursor: context-menu !important;
}
</style>
