<template>
  <v-card width="100%">
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Detalle del proceso de distribución de aportes web N° {{detallesDistribucionAportes.impAporte_id}}</v-card-title
      >
      <v-data-table
        :headers="headers"
        :items="detallesDistribucionAportes.detalleAportes"
        class="elevation-1"
      >
      </v-data-table>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-col cols="3" class="p-2">
          <v-tooltip left max-width="40%">
            <template v-slot:activator="{ attrs }">
              <v-btn
                color="primary"
                class="to-right fontsize"
                v-bind="attrs"
                outlined
                @click="exportExcel"
              >
                Exportar detalle
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
        <v-btn outlined @click="closeModalVerDetalle"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import helpersExcel from "@/utils/helpers/importExportExcel";
import { mapActions } from "vuex";
export default {
  props: {
    detallesDistribucionAportes: {
      required: true
    }
  },
  data: () => ({
    headers: [
      {
        text: "Período",
        value: "periodo",
        sortable: false
      },
      {
        text: "CUIL",
        value: "cuil",
        sortable: false
      },
      {
        text: "Afiliado",
        value: "nombreAfiliado",
        sortable: false
      },
      {
        text: "Empresa",
        value: "empresa",
        sortable: false
      },
      {
        text: "Sueldo",
        value: "sueldo",
        sortable: false,
        align:"end"
      },
      {
        text: "Aporte",
        value: "aporte",
        sortable: false,
        align:"end"
      },
      {
        text: "Contribución",
        value: "contribucion",
        sortable: false,
        align:"end"
      },
      {
        text: "Otros",
        value: "otros",
        sortable: false,
        align:"end"
      },
      {
        text: "Total",
        value: "total",
        sortable: false,
        align:"end"
      }
    ]
  }),
  methods: {
    ...mapActions({
      setAlert: "user/setAlert"
    }),
    closeModalVerDetalle() {
      this.$emit("closeModalVerDetalle");
    },
    exportExcel() {
      let result = [];

      this.detallesDistribucionAportes.detalleAportes.forEach((x, index) => {
        result.push({
          ["Fecha"]: this.detallesDistribucionAportes.fechaProceso,
          ["Período"]: x.periodo,
          ["CUIL"]: x.cuil,
          ["Afiliado"]: x.nombreAfiliado,
          ["Convenio"]: this.detallesDistribucionAportes.osNombre,
          ["CUIT"]: x.cuit,
          ["Empresa"]: x.empresa,
          ["Sueldo"]: x.sueldo,
          ["Aporte"]: x.aporte,
          ["Contribución"]: x.contribucion,
          ["Otros"]: x.otros,
          ["Total"]: x.total
        });
      });

      if (this.detallesDistribucionAportes.detalleAportes.length > 0) {
        let formato = {
          filas: result,
          hoja: "Resultado"
        };
        helpersExcel.excelExport(
          formato,
          "Detalle de proceso de distribución de aportes web N° " +
            this.detallesDistribucionAportes.impAporte_id
        );
      } else {
        this.setAlert({
          type: "warning",
          message:
            "El proceso N° " +
            this.detallesDistribucionAportes.impAporte_id +
            " no tiene detalles."
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fontsize {
  font-size: 12px;
}
</style>
