<template>
  <v-card>
    <v-container>
      <v-card-title class="py-0">
        <span class="pl-4 primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text class="py-0">
        <!-- Data Table -->
        <v-card flat>
          <v-col cols="12">
            <v-data-table
              :headers="distribucionAportesHeaders"
              :items="distribucionAportesItems"
              v-model="selected"
              item-key="idPeriodo"
              show-select
              class="elevation-1"
              :search="search"
              @toggle-select-all="selectAllToggle"
            >
              <template
                v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
              >
                <v-simple-checkbox
                  :disabled="item.benId === 0"
                  :value="item.benId === 0 ? false : isSelected"
                  color="primary"
                  @input="select($event)"
                  :ripple="false"
                ></v-simple-checkbox>
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-row>
                    <v-col cols="9">
                      <v-text-field
                        v-model="search"
                        :append-icon="searchIcon"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
        </v-card>
      </v-card-text>

      <v-card-actions class="mr-5 py-0">
        <v-spacer></v-spacer>
        <v-btn
          outlined
          color="primary"
          class="to-right"
          :loading="loadingRecalculateBtn"
          @click="recalculate()"
        >
          Recalcular
        </v-btn>
        <v-btn outlined class="to-right" @click="closeModal"> Cerrar </v-btn>
        <v-btn
          color="primary"
          class="to-right"
          :loading="loadingGrabarBtn"
          @click="grabarProcesoDistribucion()"
          :disabled="!selected.length > 0 || loadingGrabarBtn"
        >
          Grabar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";

export default {
  name: "GrabarProcesoDistribucionAportes",
  props: {
    listaProcesadaDistribucionAportes: {
      Type: Array,
      required: true
    },
    procesosDistribucion: {
      Type: Array,
      required: true
    },
    osId: {
      Type: Number,
      required: true
    },
    empId: {
      Type: Number,
      required: true
    }
  },
  created() {
    this.distribucionAportesItems = this.listaProcesadaDistribucionAportes;
    this.selectAllToggle();
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: "Proceso de distribución de aportes y contribuciones",
    loadingGrabarBtn: false,
    loadingRecalculateBtn: false,
    distribucionAportesHeaders: [
      {
        text: "Período",
        align: "start",
        sortable: false,
        value: "periodo"
      },
      { text: "CUIL aportante", sortable: false, value: "cuilAportante" },
      { text: "Aporte", value: "aporte", sortable: false, align: "end" },
      {
        text: "Contribución",
        value: "contribucion",
        sortable: false,
        align: "end"
      },
      { text: "Otros", value: "otros", sortable: false, align: "end" },
      { text: "Origen", value: "origen", sortable: false },
      { text: "Total neto", sortable: false, value: "totalNeto", align: "end" }
    ],
    distribucionAportesItems: [],
    searchIcon: enums.icons.SEARCH,
    search: "",
    selected: []
  }),
  methods: {
    ...mapActions({
      procesarProcesoDistribucionAportes:
        "aportes/procesarProcesoDistribucionAportes",
      saveProcesoDistribucionAporte: "aportes/saveProcesoDistribucionAporte",
      setAlert: "user/setAlert"
    }),
    async recalculate() {
      this.loadingRecalculateBtn = true;
      const allItemsEliminadosFromTable = this.distribucionAportesItems.filter(
        x => !this.selected.includes(x)
      );
      // filtro por origen
      const itemsEliminadosDDJJ = allItemsEliminadosFromTable.filter(
        x => x.origen === "DDJJ"
      );
      const itemsEliminadosAportes = allItemsEliminadosFromTable.filter(
        x => x.origen === "Aportes"
      );
      const data = {
        procesosDistribucion: this.procesosDistribucion,
        empId: this.empId,
        idsEliminadosDDJJ: itemsEliminadosDDJJ.map(x => x.idPeriodo),
        idsEliminadosAportes: itemsEliminadosAportes.map(x => x.idPeriodo)
      };
      try {
        const response = await this.procesarProcesoDistribucionAportes(data);
        if (response.status === 200) {
          this.loadingRecalculateBtn = false;
          this.distribucionAportesItems = response.data.data;
          this.selected = this.distribucionAportesItems.filter(
            x => x.totalNeto !== "0,00" && x.benId > 0
          );
        }
      } catch (error) {
        this.loadingRecalculateBtn = false;
      }
    },
    selectAllToggle() {
      if (this.selected.length != this.distribucionAportesItems.length) {
        this.selected = [];
        // Agrego una validacion particular de esta pantalla para que no me seleccione los items no validos
        this.selected = this.distribucionAportesItems.filter(x => x.benId > 0);
        this.setAlert({
          type: "info",
          message: "Todos los registros han sido seleccionados."
        });
      } else {
        this.selected = [];
        this.setAlert({
          type: "info",
          message: "Todos los registros han sido deseleccionados."
        });
      }
    },
    async grabarProcesoDistribucion() {
      this.loadingGrabarBtn = true;
      const data = {
        osId: this.osId,
        empId: this.empId,
        procesosDistribuciones: this.selected
      };
      try {
        const response = await this.saveProcesoDistribucionAporte(data);
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Grabado con éxito."
          });
          this.closeAndReload();
        }
      } catch (error) {
        this.loadingGrabarBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeModal");
    },
    closeAndReload() {
      this.$emit("closeAndReload");
    }
  }
};
</script>

<style scoped></style>
