<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form-aporte-contrib"
          form="form-aporte-contrib"
          id="form-aporte-contrib"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="6" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="periodo"
                label="Período"
                outlined
                dense
                clearable
                v-mask="'######'"
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                hint="Formato AAAAMM"
                :rules="rules.required.concat(rules.periodoYyyyMm)"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" sm="12" class="py-0">
              <currency-input
                label="Aporte"
                v-model="aporte"
                oulined
                class="right-input"
                dense="false"
                outlined="false"
                :options="currencyOptions"
                :rules="[rules.maxLength(aporte, 18)]"
              >
              </currency-input>
            </v-col>
            <v-col cols="6" md="6" sm="12" class="py-0">
              <currency-input
                label="Contribución"
                v-model="contribucion"
                oulined
                dense="false"
                class="right-input"
                outlined="false"
                :options="currencyOptions"
                :rules="[rules.maxLength(contribucion, 18)]"
              >
              </currency-input>
            </v-col>
            <v-col cols="6" md="6" sm="12" class="py-0">
              <currency-input
                label="Otros"
                v-model="otros"
                oulined
                class="right-input"
                dense="false"
                outlined="false"
                :options="currencyOptions"
                :rules="[rules.maxLength(otros, 18)]"
              >
              </currency-input>
            </v-col>
            <v-col cols="6" sm="12" md="6" class="py-0">
              <v-autocomplete
                v-model="origenSelected"
                :items="origenes"
                label="Origen para distribuir"
                item-text="value"
                item-value="value"
                outlined
                clearable
                required
                @change="setRulesPeriodoConsulta()"
                :rules="rules.required"
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" md="6" sm="12" class="py-0">
              <v-text-field
                v-model="periodoConsultaOrigen"
                label="Período de consulta"
                outlined
                dense
                clearable
                onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                :hint="
                  origenSelected == null
                    ? 'Seleccionar un origen'
                    : 'Formato AAAAMM'
                "
                persistent-hint
                v-mask="'######'"
                :rules="rulesPeriodoConsulta"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-switch
                v-model="esFiscalizacion"
                label="Es fiscalización"
                class="ma-0"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form-aporte-contrib"
          color="primary"
          :loading="loadingBtn"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import rules from "@/utils/helpers/rules";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";

export default {
  name: "AporteContribucionADistribuir",
  directives: { mask },
  components: { CurrencyInput },
  props: {
    empresa: {
      Type: Object,
      required: true
    },
    listaAportesContribuciones: {
      Type: Array,
      required: true
    }
  },
  data: () => ({
    isFormValid: false,
    formEditTitle: "Nuevo registro",
    rules: rules,
    periodo: null,
    esFiscalizacion: false,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0.0,
        max: 999999999999999.99
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    aporte: 0,
    contribucion: 0,
    otros: 0,
    periodoConsultaOrigen: null,
    origenes: [
      {
        id: 1,
        value: "DDJJ"
      },
      {
        id: 2,
        value: "Aportes"
      }
    ],
    origenSelected: null,
    loadingBtn: false,
    rulesPeriodoConsulta: []
  }),
  methods: {
    ...mapActions({
      agregarAportesContribucionesADistribuir:
        "aportes/agregarAportesContribucionesADistribuir",
      setAlert: "user/setAlert"
    }),
    setRulesPeriodoConsulta() {
      if (this.origenSelected == null)
        this.rulesPeriodoConsulta = rules.required;
      else {
        this.rulesPeriodoConsulta = rules.required.concat([
          rules.maxLength(this.periodoConsultaOrigen, 6)
        ]);
        this.periodoConsultaOrigen = null;
      }
    },
    async saveEdit() {
      if (
        (this.otros === 0 || this.otros == null) &&
        (this.aporte === 0 || this.aporte == null) &&
        (this.contribucion === 0 || this.contribucion == null)
      ) {
        this.setAlert({
          type: "warning",
          message:
            "Uno o más de los campos aporte, contribución u otros tienen que ser mayor a 0."
        });
        return;
      } else if (
        this.periodoConsultaOrigen.length < 4 ||
        this.periodoConsultaOrigen.length > 7 ||
        this.periodoConsultaOrigen.length === 5
      ) {
        this.setAlert({
          type: "warning",
          message:
            "El período ingresado no corresponde a ningún formato especificado."
        });
        return;
      }
      if (
        this.listaAportesContribuciones.some(
          x =>
            x.periodo == this.periodo &&
            x.periodoConsulta == this.periodoConsultaOrigen &&
            x.origen == this.origenSelected
        )
      ) {
        this.setAlert({
          type: "warning",
          message: "Ya existe un registro con el período y origen"
        });
        return;
      }
      this.loadingBtn = true;
      const data = {
        empresa: {
          id: this.empresa.id,
          value: this.empresa.cuit
        },
        periodo: this.periodo,
        aporte: this.aporte,
        contribucion: this.contribucion,
        otros: this.otros,
        origen: this.origenSelected,
        periodoConsulta: this.periodoConsultaOrigen,
        esFiscalizacion: this.esFiscalizacion
      };
      try {
        const response = await this.agregarAportesContribucionesADistribuir(
          data
        );
        if (response.status === 200) {
          this.setAlert({
            type: "success",
            message: "Registro agregado con éxito."
          });
          this.$emit("setRegistro", response.data.data);
        }
        this.loadingBtn = false;
      } catch (error) {
        this.loadingBtn = false;
      }
    },
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
