var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showExpand),expression:"!showExpand"}],staticClass:"pr-0 pt-0 pb-0",attrs:{"cols":"12","md":_vm.showHelp ? 8 : 11,"sm":12}},[_c('GoBackBtn',{attrs:{"routeToGo":_vm.routeToGo,"tabId":false}}),_c('PageHeader',{attrs:{"title":_vm.title}}),_c('v-card',{staticClass:"mx-auto my-12 mt-2 mb-4 pt-0",attrs:{"max-width":"1200","outlined":""}},[_c('v-container',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"10","align":"left"},on:{"click":function($event){_vm.showFilters = true}}},[(!_vm.showFilters)?_c('FiltersSelected',{attrs:{"filters":_vm.filtersApplied}}):_vm._e()],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"2","align":"right","align-self":"center"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"primary--text",on:{"click":function($event){$event.stopPropagation();_vm.showFilters = !_vm.showFilters}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.closeFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(!_vm.showFilters ? "Mostrar filtros" : "Ocultar filtros"))])])],1)],1),_c('v-expand-transition',{attrs:{"mode":"out-in"}},[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}],ref:"filters-form",attrs:{"id":"filters-form"},on:{"submit":function($event){$event.preventDefault();return _vm.applyFilters()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{ref:"menuDesde",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha desde","append-icon":_vm.calendarIcon,"outlined":"","clearable":"","dense":"","hint":"Formato DD/MM/AAAA","rules":[
                          (!!_vm.fechaDesdeSelected &&
                            !!_vm.fechaHastaSelected &&
                            new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                              new Date(_vm.parseDateToIso(_vm.fechaHastaSelected))) ||
                            'Formato incorrecto'
                        ]},on:{"blur":function($event){_vm.fechaDesde = _vm.parseDateToIso(_vm.fechaDesdeSelected)}},model:{value:(_vm.fechaDesdeSelected),callback:function ($$v) {_vm.fechaDesdeSelected=$$v},expression:"fechaDesdeSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDesde),callback:function ($$v) {_vm.menuDesde=$$v},expression:"menuDesde"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaDesdeSelected = _vm.formatDate(_vm.fechaDesde)},"input":function($event){_vm.menuDesde = false}},model:{value:(_vm.fechaDesde),callback:function ($$v) {_vm.fechaDesde=$$v},expression:"fechaDesde"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha hasta","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","outlined":"","dense":"","clearable":"","rules":[
                          (!!_vm.fechaDesdeSelected &&
                            !!_vm.fechaHastaSelected &&
                            new Date(_vm.parseDateToIso(_vm.fechaDesdeSelected)) <=
                              new Date(_vm.parseDateToIso(_vm.fechaHastaSelected))) ||
                            'Formato incorrecto'
                        ]},on:{"blur":function($event){_vm.fechaHasta = _vm.parseDateToIso(_vm.fechaHastaSelected)}},model:{value:(_vm.fechaHastaSelected),callback:function ($$v) {_vm.fechaHastaSelected=$$v},expression:"fechaHastaSelected"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuHasta),callback:function ($$v) {_vm.menuHasta=$$v},expression:"menuHasta"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"change":function($event){_vm.fechaHastaSelected = _vm.formatDate(_vm.fechaHasta)},"input":function($event){_vm.menuHasta = false}},model:{value:(_vm.fechaHasta),callback:function ($$v) {_vm.fechaHasta=$$v},expression:"fechaHasta"}})],1)],1)],1),_c('v-row',{staticClass:"pr-4 pl-4 pt-0",attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"size":"28"},on:{"click":_vm.resetForm}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.clearFiltersIcon)+" ")])]}}])},[_c('span',[_vm._v("Limpiar filtros")])]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.isFormValid,"elevation":"2","small":"","type":"submit","form":"filters-form"}},[_vm._v(" Aplicar ")])],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.procesosDistribucion,"item-key":"impAporte_id","search":_vm.search,"loading":_vm.isLoading,"show-expand":"","expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.esDistribucion",fn:function(ref){
                        var item = ref.item;
return [(item.esDistribucion)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"item.esFiscalizacion",fn:function(ref){
                        var item = ref.item;
return [(item.esFiscalizacion)?_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"append-icon":_vm.searchIcon,"label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.canCreate)?_c('v-col',{attrs:{"cols":"2","align":"end"}},[_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openModalEditProceso()}}},[_vm._v(" Nuevo ")])],1):_vm._e()],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('section',_vm._g({staticStyle:{"display":"inline"}},on),[_c('v-icon',_vm._g(_vm._b({attrs:{"disabled":item.fechaAnula !== '',"size":"20"},on:{"click":function($event){return _vm.verDetalleProceso(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.seeIcon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.fechaAnula === "" ? "Ver detalles" : "El proceso ya ha sido anulado"))])]),(_vm.canAnular)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('section',_vm._g({staticStyle:{"display":"inline"}},on),[_c('v-icon',_vm._g(_vm._b({attrs:{"disabled":item.fechaAnula !== '',"size":"20"},on:{"click":function($event){return _vm.anularProceso(item.impAporte_id)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.anularIcon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.fechaAnula === "" ? "Anular proceso" : "El proceso ya ha sido anulado"))])]):_vm._e()]}},{key:"item.data-table-expand",fn:function(ref){
                        var item = ref.item;
                        var isExpanded = ref.isExpanded;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"},on:{"click":function($event){return _vm.handleExpansion(item, isExpanded)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(isExpanded ? _vm.accountCheckIcon : _vm.accountIcon))])]}}],null,true)},[_c('span',[_vm._v("Usuarios")])])]}},{key:"expanded-item",fn:function(ref){
                        var headers = ref.headers;
                        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Usuario alta:")]),_vm._v(" "+_vm._s(item.usuarioAlta)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Fecha alta:")]),_vm._v(" "+_vm._s(item.fechaAlta)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Usuario anulación:")]),_vm._v(" "+_vm._s(item.usuarioAnula ? item.usuarioAnula : "Sin datos")+" ")]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('strong',[_vm._v("Fecha anulación:")]),_vm._v(" "+_vm._s(item.fechaAnula ? item.fechaAnula : "Sin datos")+" ")])],1)],1)]}}],null,true)})],1)],1),_c('v-col',{staticClass:"to-right pt-6 px-0",attrs:{"cols":"12","md":!_vm.showHelp ? 1 : _vm.showExpand ? 12 : 4}},[_c('Ayuda',{attrs:{"optionCode":_vm.optionCode,"pantallaTitle":_vm.title},on:{"toggleExpand":function($event){_vm.showExpand = !_vm.showExpand},"toggleHelp":function($event){return _vm.toggleHelp()},"toggleIcon":function($event){return _vm.toggleIcon()}}})],1)],1),(_vm.openModalVerDetalle)?_c('v-dialog',{attrs:{"max-width":"80%","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeModalVerDetalle($event)}},model:{value:(_vm.openModalVerDetalle),callback:function ($$v) {_vm.openModalVerDetalle=$$v},expression:"openModalVerDetalle"}},[_c('VerDetalleProcesoDistribucion',{attrs:{"detallesDistribucionAportes":_vm.procesoADetallar},on:{"closeModalVerDetalle":_vm.closeModalVerDetalle}})],1):_vm._e(),_c('DeleteDialog',{attrs:{"titleProp":_vm.titleAnular,"textDelete":_vm.textAnular,"maxWidth":'40%',"confirmButtonText":'Anular',"openDelete":_vm.showAnularModal,"isLoading":_vm.loadingAnular},on:{"update:openDelete":function($event){_vm.showAnularModal=$event},"update:open-delete":function($event){_vm.showAnularModal=$event},"onDeleteItem":function($event){return _vm.confirmAnular()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }